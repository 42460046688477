import React from 'react'
import Helmet from 'react-helmet'

import '../../styles/index.scss'

const Layout = ({ children, data }) => (
  <div>
    <Helmet
      title="Jakša Bašić"
      meta={[
        { name: 'description', content: 'Personal website' },
        { name: 'keywords', content: 'web developer, remote' },
        { name: 'viewport', content: 'width=device-width, initial-scale=1.0' }
      ]}
    />
    <div>
      {children}
    </div>
  </div>
)

export default Layout
